<template>
  <div class="signup-box">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
.signup-box .title
  text-align: center
  margin-top: 0!important
  margin-bottom: 40px!important

  @media screen and (max-width: 600px)
    margin-bottom: 32px!important

.signup-type .title
  @media screen and (max-width: 600px)
    font-size: 32px!important
    line-height: 32px!important
    margin-bottom: 32px!important
</style>
